import React from 'react'
import SideBar from '../AdminSideBar/AdminSideBar'
import { Box, Grid, Heading, Stack } from '@chakra-ui/react'
import Header from '../AdminHeader/AdminHeader'
import Profile from '../../User/Profile'

const AdminProfile= () => {
    return (
        <>
        <Header />
            <Grid
                w={"100%"}
                height={"100vh"}
               
                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"1rem"}
            > 
                <SideBar />
                <Box   bgColor={'#ffff'}  overflowY={"auto"} p={3}>
                <Heading children={'Admin Profile'} size={'lg'} m={5} textAlign={'center'} textTransform={'uppercase'}/>
              
              <Profile />
           
                </Box>
            </Grid>
        </>

    )
}

export default AdminProfile