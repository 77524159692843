import { createReducer } from '@reduxjs/toolkit';

export const QuotationReducer = createReducer(
  {},
  {
    QuotationRequest: state => {
      state.loading = true;
    },
    QuotationSuccess: (state, action) => {
      state.loading = false;
      // state.Quotation = action.payload;
      state.message = action.payload.message;
    },
    QuotationFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    GetAllQuotationRequest: state => {
      state.loading = true;
    },
    GetAllQuotationSuccess: (state, action) => {
      state.loading = false;
      state.quotations = action.payload.quotations;
      state.message = action.payload.message;
    },
    GetAllQuotationFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    DeleteQuotationRequest: state => {
      state.loading = true;
    },
    DeleteQuotationSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    DeleteQuotationFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;      
    }, 

    AdminGetAllQuotationDataRequest: state => {
      state.loading = true;
    },
    AdminGetAllQuotationDataSuccess: (state, action) => {
      state.loading = false;
      state.adminQuotations = action.payload;
      state.message = action.payload.message;
    },
    AdminGetAllQuotationDataFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    AdminDeleteQuotationRequest: state => {
      state.loading = true;
    },
    AdminDeleteQuotationSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    AdminDeleteQuotationFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;      
    }, 
  }
);
