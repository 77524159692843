import {
	Avatar, Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton, Button, Heading, HStack, Stack, Text, VStack, Tab, TabList, TabPanel, TabPanels, Tabs,
	useDisclosure,
	Input,
	Textarea,
	Select
} from "@chakra-ui/react";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AdminGetAllInvoiceData, DeleteInvoice, GetAllInvoice } from '../../redux/actions/InvoiceActions';
import { MdDelete } from 'react-icons/md';
import Marque from '../Markque/Marque';
import { AdminLoadAllContacedUser, AdminLoadAllUser, LoadUser, UserBrandeLogoApi, UserUpdateDetailsApi } from '../../redux/actions/UserActions';
import { FaStar } from 'react-icons/fa6';
import UserInvoice from "./UserInvoice";
import UserClients from "./UserClients";
import UserQuotation from "./UserQuotation";
import { AdminGetAllQuotationData, GetAllQuotation } from "../../redux/actions/QuotationActions";
import { Country, State, City } from 'country-state-city';


const Profile = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [brandLogo, setBrandLogo] = useState("")
	const [showModel, setShowModel] = useState();

	const [name, SetName] = useState('');
	const [phone, SetPhone] = useState('');
	const [email, SetEmail] = useState('');
	const [businessName, SetBusinessName] = useState('');
	const [website, SetWebsite] = useState('');
	const [businessSince, SetBusinessSince] = useState('');
	const [gstNumber, SetGstNumber] = useState('');
	const [address, SetAddress] = useState('');

	const [CompanyCity,SetCompanyCity]=useState('')
    const [CompanyState,SetCompanyState]=useState('')
    const [CompanyCountry,SetCompanyCountry]=useState('')
    let testCompanyStatecode=CompanyState.split(',')[1]


	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { quotations, loading } = useSelector(
		state => state.quotationContainer
	);
	const { user } = useSelector(
		state => state.userContainer
	);
	const showModelHandler = i => {
		setShowModel(i);
		onOpen();
	};
	const deleteBills = async (_id) => {

		await dispatch(DeleteInvoice(_id))
		dispatch(GetAllInvoice())

	}
	const userUpdateAvatarHandler = async (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.readyState === 2) {
				setBrandLogo(reader.result);
			}
		};


	};
	const ChangeAvatarHandler = async () => {
		await dispatch(UserBrandeLogoApi(brandLogo));
		await dispatch(LoadUser())

		onClose();
		navigate('/profile');
	};
	const ChangeUserDetailsHandler = async () => {
		await dispatch(UserUpdateDetailsApi(name, email, phone, businessName, gstNumber, website, businessSince,CompanyCity,CompanyState, CompanyCountry,address  ));
		await dispatch(LoadUser())

		onClose();
		SetName('')
		SetPhone('')
		SetEmail('')
		SetBusinessName('')
		SetBusinessSince('')
		SetWebsite('')
		SetGstNumber('')
		// navigate('/profile');
	};
	const GoToDashboard=async ()=>{
	 dispatch(GetAllInvoice())
	 dispatch(AdminLoadAllUser())
	 dispatch(AdminGetAllInvoiceData())
	 dispatch(AdminGetAllQuotationData())
	 dispatch(AdminLoadAllContacedUser())
		navigate('/admin/dashboard')
	}
	useEffect(() => {
		dispatch(GetAllInvoice())
		dispatch(GetAllQuotation())
		// dispatch(AdminGetAllInvoiceData())
	}, [])

	return (
		// <Box w={'100%'} p={'10'} fontFamily={'Roboto'}>
		//     <VStack spacing={'8'} >
		//     <Marque title={'More Features Coming Soon! '} />
		//       <Heading letterSpacing={'5px'} textTransform={'uppercase'} fontSize={['4vmax','5vmax']}  as={'h2'} textAlign={'center'} children={`Welcome ${user.name} in quickbill.ai`} />
		//       <Text fontSize={['md','lg','xl','2xl']} textAlign={'center'} children={'Your Data are our First Priority'} />
		//       <Button _hover={{bgGradient:'linear(to-l,#FF0080, #7928CA)'}} bgGradient='linear(to-l, #7928CA, #FF0080)' color={'white'} letterSpacing={'5px'} size={['md','lg']} variant={'solid'}  onClick={()=>navigate('/tryfree')} children={'Invoice Now'} />
		//     </VStack>
		// {
		//   user && user.role==='admin'?(
		//     <Daskboard />
		//   ):(
		//     <TableContainer w={'100%'}  fontFamily={'Roboto'} fontSize={['xs','sm','md','lg']}  m={'5.5vmax 0'}>
		//     <Table  variant="simple">
		//         <Thead  bgGradient='linear(to-l, #7928CA, #FF0080)'>
		//             <Tr >
		//                 <Th color={'white'}>SN#</Th>
		//                 <Th color={'white'} isNumeric>Invoice No</Th>
		//                 <Th color={'white'} isNumeric>Client Name</Th>
		//                 <Th color={'white'} isNumeric>Items</Th>
		//                 <Th color={'white'} isNumeric>Date</Th> 
		//                 <Th color={'white'} isNumeric>Amount</Th>
		//             </Tr>
		//         </Thead>
		//         <Tbody>
		//           {
		//            bills && bills.map((item,i)=>(
		//               <Tr key={i} >
		//                 <Td>{i+1}</Td>
		//                 <Td isNumeric>{item.invoiceNumber}</Td>
		//                 <Td>{item.ClientCompanyName}</Td>
		//                 <Td>{item.invoiceItem.map((item)=>(
		//                   <p>{item}</p>
		//                 ))}</Td>
		//                 <Td isNumeric >{item.invoiceDate}</Td>
		//                 <Td isNumeric>
		//                   {item.totalAmountWithTax}
		//                   <Button isLoading={loading} ml={'1.5vmax'} size={'sm'} onClick={()=>deleteBills(item._id)} colorScheme={'purple'} children={<MdDelete />} />
		//                   </Td>
		//               </Tr>
		//             ))
		//           }

		//         </Tbody>
		//     </Table>
		// </TableContainer>
		//   )
		// }

		// </Box>
		<>
			<Stack bgColor={'white'} borderRadius={'10px'} boxShadow='lg' spacing={'10'} w={['100%', '100%', '100%','90%']} p={['3', '6']} m={'1.5vmax auto'} alignItems={'center'} justifyContent={'flex-start'} direction={{ base: 'column', md: 'row', lg: 'row' }}>

				{/* Avatar and change Model */}
				<VStack>
					<Avatar w={'15vmax'} h={'15vmax'} src={user?.brandLogo?.url} name={"brandLogo"} />
					<Button onClick={() => showModelHandler(1)} variant={'link'} children={'change Logo'} colorScheme={'purple'} />

					<Modal isOpen={showModel === 1 ? isOpen : null} onClose={onClose}>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>Change Your Profile</ModalHeader>
							<ModalCloseButton  color={'purple'}/>
							<ModalBody>
								<VStack spacing={'4'}>
									<Input
										css={{
											'&::file-selector-button': {
												cursor: 'pointer',
												width: '110%',
												marginLeft: '-5%',
												border: 'none',
												height: '100%',
												color: 'blue',
												backgroundColor: 'white',
											},
										}}
										onChange={userUpdateAvatarHandler}
										type="file"
										placeholder="files"
										accept="image/*"
									/>
								</VStack>
							</ModalBody>

							<ModalFooter>
								<Button
									onClick={ChangeAvatarHandler}
									children={'submit'}
									mr={3}
									variant={'solid'}
									colorScheme={'purple'}
									isLoading={loading}
								/>
								<Button
									children={'Close'}
									colorScheme="pink" variant={'outline'}
									onClick={onClose}
									isDisabled={loading}
								/>
							</ModalFooter>
						</ModalContent>
					</Modal>
				</VStack>

				{/* User Details and change Model */}
				<VStack w={'100%'} p={2} spacing={'1'} alignItems={'flex-start'}>
				

                         <Stack w={'100%'} direction={{base:'column',md:'column',lg:'row'}}>

                           <Stack  lineHeight={[.3,0.8]} alignItems={'flex-start'} w={['100%', '100%', '100%','50%']}>
                            <HStack   fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                              <Text  children={'Name:'} />
                              <Text fontSize={'1rem'} fontWeight={'400'} children={user?.name ? user?.name  : <span style={{color:'red'}}>Not Update </span>} />
                            </HStack>

                           <HStack fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                              <Text children={'Contact No:'} />
                              <Text fontSize={'1rem'} fontWeight={'400'}  children={user?.phone ? user?.phone : <span style={{color:'red',fontSize:'.9rem'}}>Not Updated</span>}  />
                           </HStack>

                          <HStack fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                      		  <Text children={'Company Name:'} />
                              <Text fontSize={'1rem'} fontWeight={'400'} children={user?.businessName ? user?.businessName : <span style={{color:'red',fontSize:'.9rem'}}>Not Updated </span>} />
                          </HStack>

                          <HStack fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                              <Text children={'GST Number:'} />
                              <Text  fontSize={'1rem'}fontWeight={'400'} children={user?.gstNumber ? user?.gstNumber : <span style={{color:'red',fontSize:'.9rem'}}>Not Updated </span>} />
                         </HStack>
					 
                          </Stack>


						   <Stack   lineHeight={[.3,0.8]} alignItems={'flex-start'} w={['100%', '100%', '100%','50%']}>
                           <HStack fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                            <Text children={'Email:'} />
                            <Text fontSize={'1rem'} fontWeight={'400'} children={user?.email ? user?.email : <span style={{color:'red',fontSize:'.9rem'}}>Not Updated </span>} />
                             </HStack>

                             <HStack  w={'80%'} fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                               <Text children={'Website:'} />
                               <Text w={'100%'} textOverflow={'ellipsis'} overflow={'hidden'} whiteSpace={'nowrap'}  fontSize={'1rem'} fontWeight={'400'} children={user?.website ? user?.website :<span style={{color:'red',fontSize:'.9rem'}}>Not Updated </span>} />
                             </HStack>

                             <HStack fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                               <Text children={'Company Developed:'} />
                               <Text fontSize={'1rem'} fontWeight={'400'} children={user?.businessSince ? user?.businessSince : <span style={{color:'red',fontSize:'.9rem'}}>Not Updated </span>}/>
                             </HStack>
							 
                             <HStack w={'80%'}  fontSize={{ base: '1rem', md: '1.1rem' }} fontWeight={'600'}>
                               <Text children={'Address:'} />
                               <Text style={{ display: '-webkit-box',webkitLineClamp: 3, webkitBoxOrient: 'vertical', overflow: 'hidden',  lineHeight:'1.4', textOverflow: 'ellipsis'}} fontSize={'1rem'} fontWeight={'400'} children={`${user?.address}, ${user?.CompanyCity},${user?.CompanyState} ,${user?.CompanyCountry} `?`${user?.address}, ${user?.CompanyCity}${user?.CompanyState}, ${user?.CompanyCountry}`:<span style={{color:'red',fontSize:'.9rem'}}>Not Updated </span>}/>
                             </HStack>
							
                              </Stack>
                           </Stack>

					<Button onClick={() => showModelHandler(2)} variant={'link'} children={'Update Details'} colorScheme={'purple'} />
					{/* go to deshboard btn */}

					{
						user?.role === 'admin' ? (
							<>
								<Button mt={5} size={['sm', 'md']} onClick={() => GoToDashboard()} children={'Go To Dashboard'} colorScheme={'pink'} />
							</>
						) : null
					}
					<Modal isOpen={showModel === 2 ? isOpen : null} onClose={onClose}>
						<ModalOverlay />
						<ModalContent>
							<ModalHeader>Change Your Details</ModalHeader>
							<ModalCloseButton color={'purple'}/>
							<ModalBody>
								<VStack spacing={'4'}>
									<HStack>
										<Input
										value={name}
										onChange={e => SetName(e.target.value)}
										type="text"
										placeholder="name" border={'1px solid rgba(151, 8, 204, 1)'}
										/>
										<Input
											value={email}
											onChange={e => SetEmail(e.target.value)}
											type="email"
											placeholder="email" border={'1px solid rgba(151, 8, 204, 1)'}
										/> 
									</HStack>
									<Input
										value={phone}
										onChange={e => SetPhone(e.target.value)}
										type="number"
										placeholder="phone" border={'1px solid rgba(151, 8, 204, 1)'}
									/>
									<Input
										value={businessName}
										onChange={e => SetBusinessName(e.target.value)}
										type="text"
										placeholder="business Name" border={'1px solid rgba(151, 8, 204, 1)'}
									/>
									<Input
										value={website}
										onChange={e => SetWebsite(e.target.value)}
										type="text"
										placeholder="website link" border={'1px solid rgba(151, 8, 204, 1)'}
									/>
									<HStack>
										<Input
											value={businessSince}
											onChange={e => SetBusinessSince(e.target.value)}
											type="date" 
											placeholder="business Since" border={'1px solid rgba(151, 8, 204, 1)'}
										/>
										<Input
											value={gstNumber}
											onChange={e => SetGstNumber(e.target.value)}
											type="text"
											placeholder="gst Number" border={'1px solid rgba(151, 8, 204, 1)'}
										/>
									</HStack>
									<Input
										value={address}
										onChange={e => SetAddress(e.target.value)}
										type="text"
										placeholder="address" border={'1px solid rgba(151, 8, 204, 1)'}
									/>
									<Select onChange={(e) => { SetCompanyCountry(e.target.value) }} value={CompanyCountry} size={['sm', 'md']} borderRadius={5} border={'1px solid rgba(151, 8, 204, 1)'}>
                                    	<option style={{ color: 'black' }} value={Country.getCountryByCode('IN').name}>{Country.getCountryByCode('IN').name}</option>
                                	</Select>

									<HStack>
									<Select  onChange={(e) => { SetCompanyState(e.target.value) }} value={CompanyState} placeholder='Select State' size={['sm', 'md']} border={'1px solid rgba(151, 8, 204, 1)'}>
                                    	{
                                    	    State.getStatesOfCountry('IN').map((item, i) => (
                                    	        <option key={i} style={{ color: 'black' }} value={`${item.name},${item.isoCode}`}>{`${item.name}, ${item.isoCode}`} </option>
                                    	    ))
                                    	}
                                	</Select>
									<Select onChange={(e) => { SetCompanyCity(e.target.value) }} value={CompanyCity} placeholder='Select City' size={['sm', 'md']} border={'1px solid rgba(151, 8, 204, 1)'}>
                                        {
                                            City.getCitiesOfState('IN', testCompanyStatecode).map((item, i) => (
                                                <option key={i} style={{ color: 'black' }} value={item.name}>{item.name}</option>
                                            ))
                                        }
                                    </Select>
									</HStack>
								</VStack>
							</ModalBody>

							<ModalFooter>
								<Button
									onClick={ChangeUserDetailsHandler}
									children={'submit'}
									mr={3}
									// variant={'solid'}
									colorScheme={'purple'}
									isLoading={loading}
								/>
								<Button
									children={'Close'}
									colorScheme="pink"
									variant={'outline'}
									onClick={onClose}
									isDisabled={loading}
								/>
							</ModalFooter>
						</ModalContent>
					</Modal>

				</VStack>

			</Stack>

			{/* tabs start */}
			<Tabs  w={['100%', '100%', '100%','90%']} m={'1vmax auto'} size='md' >
				<TabList p={['2', '4']} >
					<Tab _selected={{ borderBottom: '3px solid purple' }} mr={'2vmax'}>Invoice</Tab>
					{/* <Tab _selected={{ borderBottom: '3px solid green' }}>Client</Tab> */}
					<Tab _selected={{ borderBottom: '3px solid purple' }}>Quotation</Tab>
				</TabList>

				<TabPanels >
					<TabPanel p={['2', '4']}>
						<UserInvoice />
					</TabPanel>
					<TabPanel p={['2', '4']}>
						<UserQuotation />
						{/* <UserClients /> */}
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};

export default Profile;
