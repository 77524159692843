import { createReducer } from '@reduxjs/toolkit';

export const UserReducer = createReducer(
  {},

  {
    signupRequest: state => {
      state.loading = true;
    },
    signupSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.message = action.payload.message;
    },
    signupFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false; 
      state.error = action.payload;
    },
    // signupWithGoogleRequest: state => {
    //   state.loading = true;
    // },
    // signupWithGoogleSuccess: (state, action) => {
    //   state.loading = false;
    //   state.isAuthenticated = true;
    //   state.user = action.payload;
    //   state.message = action.payload.message;
    // },
    // signupWithGoogleFail: (state, action) => {
    //   state.loading = false;
    //   state.isAuthenticated = false;
    //   state.error = action.payload;
    // },

    loginRequest: state => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.message = action.payload.message;
    },
    loginFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },


    AfterLoginDetailsRequest: state => {
      state.loading = true;
    },
    AfterLoginDetailsSuccess: (state, action) => {
      state.loading = false;
      // state.user = action.payload.user;
      state.message = action.payload.message;
    },
    AfterLoginDetailsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    UserChangeBrandeLogoRequest: state => {
      state.loading = true;
    },
    UserChangeBrandeLogoSuccess: (state, action) => {
      state.loading = false;
      // state.user = action.payload.user;
      state.message = action.payload.message;
    },
    UserChangeBrandeLogoFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    UserUpdateDetailsRequest: state => {
      state.loading = true;
    },
    UserUpdateDetailsSuccess: (state, action) => {
      state.loading = false;
      // state.user = action.payload.user;
      state.message = action.payload.message;
    },
    UserUpdateDetailsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    logoutRequest: state => {
      state.loading = true;
    },
    logoutSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.message = action.payload.message;
    },
    logoutFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.error = action.payload;
    },
    loadUserRequest: state => {
      state.loading = true;
    },
    loadUserSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload;
      state.message = action.payload.message;
    },
    loadUserFail: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    },

    forgetPasswordRequest: state => {
      state.loading = true;
    },
    forgetPasswordSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    forgetPasswordFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    resetPasswordRequest: state => {
      state.loading = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    resetPasswordFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    contactRequest: state => {
      state.loading = true;
    },
    contactSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    contactFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    contactWithEmailRequest: state => {
      state.loading = true;
    },
    contactWithEmailSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    contactWithEmailFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    AdminLoadUserRequest: state => {
      state.loading = true;
    },
    AdminLoadUserSuccess: (state, action) => {
      state.loading = false;
      state.allUser = action.payload;
      state.message = action.payload.message;
    },
    AdminLoadUserFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    AdminDeleteUserRequest: state => {
      state.loading = true;
    },
    AdminDeleteUserSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    AdminDeleteUserFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    AdminChangeUserRoleRequest: state => {
      state.loading = true;
    },
    AdminChangeUserRoleSuccess: (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    },
    AdminChangeUserRoleFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },


    AdminLoadContacUserRequest: state => {
      state.loading = true;
    },
    AdminLoadContacUserSuccess: (state, action) => {
      state.loading = false;
      state.allContacedUser = action.payload;
      state.message = action.payload.message;
    },
    AdminLoadContacUserFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  }
);
