import { configureStore } from '@reduxjs/toolkit';
import { UserReducer } from './reducers/UserReducers';
import { InvoiceReducer } from './reducers/InvoiceReducer';
import { QuotationReducer } from './reducers/QuotationReducer';



const Store = configureStore({
  reducer: {
    userContainer: UserReducer,
    invoiceContainer: InvoiceReducer,
    quotationContainer: QuotationReducer,
  },
});

export default Store; 
