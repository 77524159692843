import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AiFillFileText } from 'react-icons/ai'
import { FaBasketShopping, FaUser } from 'react-icons/fa6'
import { FaRegUserCircle } from "react-icons/fa";
import { HiMenuAlt4 } from 'react-icons/hi'
import { IoIosPeople } from 'react-icons/io'
import { RiDashboardFill, RiShoppingBag3Fill } from 'react-icons/ri'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IoSettings, IoNotifications } from "react-icons/io5";
import { GrContact, GrTransaction } from "react-icons/gr";
import { IoMdHome } from "react-icons/io";
import { FaBlogger } from "react-icons/fa";
import { IoLogOut } from "react-icons/io5";
import logo from '../../../img/logo.svg'
import { FaFileInvoice } from "react-icons/fa";
import './style.css'

import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/actions/UserActions';

const AdminSideBar = () => {
  const location = useLocation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const navigate=useNavigate()
  const dispatch=useDispatch()
 
  const LogoutHandler=async()=>{
    await dispatch(logout())
    navigate('/')
}

  
  return (
    <>

      <Box bg='linear-gradient(to right, #fde5d1, #f3c9ed, #f8e1e1)' className='scrollBar' display={{ base: 'none', md: 'none', lg: 'block' }} w={'100%'}  p={'1rem'} zIndex={'10'} overflowY={'auto'} __css={{ WebkitScrollSnapType: 'none' }} >
        {/* <Heading size={'lg'} as={'h2'} children={'Dashboard'} /> */}
            <Box cursor={'pointer'} ml={6} onClick={()=>navigate('/')} >
                <img src={logo} alt="logo" width={'200px'} />
            </Box>
        
        <Box bg='linear-gradient(to right, #fde5d1, #f3c9ed, #f8e1e1)' w={'100%'} m={'1rem 0'} >
          {/* <Heading fontWeight={'500'} letterSpacing={'5px'} border={'1px solid red'} m={'1rem 0'} size={'md'}  as={'h5'} children={'Dashboard'} /> */}
          <VStack bg='linear-gradient(to right, #fde5d1, #f3c9ed, #f8e1e1)' w={'100%'}  alignItems={'flex-start'} >
            <SideBarItem url={'/'} Icon={IoMdHome} location={location} text={'Home'} />
            <SideBarItem url={'/admin/dashboard'} Icon={RiDashboardFill} location={location} text={'Dashboard'} />
            <SideBarItem url={'/admin/invoice'} Icon={FaFileInvoice} location={location} text={'Invoice'} />
            <SideBarItem url={'/admin/quotation'} Icon={FaFileInvoice} location={location} text={'Quotation'} />
            <SideBarItem url={'/admin/user'} Icon={FaUser} location={location} text={'User'} />
            <SideBarItem url={'/admin/contact/user'} Icon={GrContact} location={location} text={'Contact User'} />
            {/* <SideBarItem url={'/admin/transaction'} Icon={GrTransaction } location={location} text={'Total Transaction'} /> */}
            <SideBarItem url={'/admin/profile'} Icon={FaRegUserCircle} location={location} text={'Admin Profile'} />
            {/* <SideBarItem url={'/admin/notification'} Icon={IoNotifications} location={location} text={'Notification'} /> */}

          </VStack>
          <div className="logoutWrapper"> 
            <div className="logoutBox">
              <Button onClick={()=>LogoutHandler()}  w={'100%'} rightIcon={<IoLogOut />} children={'Logout'} _hover={{bgGradient:'linear(to-l,#FF0080, #7928CA)'}} bgGradient='linear(to-l, #7928CA, #FF0080)' color={'white'} fontSize={'1.2rem'} mt={'8rem'} borderRadius={'none'} />
            </div>
            </div>
        </Box>

      </Box>
    </>
  )
}

export default AdminSideBar

const SideBarItem = ({ url, Icon, location, text }) => {
  return (
    <Link style={{ width: '100%' }} placeSelf={'stretch'} to={url} >
      <Button style={{ width: '100%', alignContent: 'flex-start', justifyContent: 'flex-start' }} w={'100%'} size={'lg'} variant='ghost'   colorScheme={location.pathname.includes(url) ? 'pink' : 'purple'} leftIcon={<Icon />} children={text} />
    </Link>
  )
}