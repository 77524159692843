import React, { useMemo, useState } from 'react'
import AdminHeader from '../AdminHeader/AdminHeader'
import AdminSideBar from '../AdminSideBar/AdminSideBar'
import { Avatar, Box, Button, Grid, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { MdDelete } from 'react-icons/md'


const userDetails = [
    { name: 'Robin zudu', comapny: 'Cyvero technologies', phone: '123456789', email: 'xyz@gmail.com', invoice: '2', date: '23/11/2024' },
    { name: 'Robin zudu', comapny: 'Cyvero technologies', phone: '123456789', email: 'xyz@gmail.com', invoice: '2', date: '23/11/2024' },
    { name: 'Robin zudu', comapny: 'Cyvero technologies', phone: '123456789', email: 'xyz@gmail.com', invoice: '2', date: '23/11/2024' },
    { name: 'Robin zudu', comapny: 'Cyvero technologies', phone: '123456789', email: 'xyz@gmail.com', invoice: '2', date: '23/11/2024' },

]
const AdminUser = () => {
    const { allUser, loading, isAuthenticated } = useSelector(
        state => state.userContainer
    );

    const itemsPerPage = 5; // Set number of items per page
    const [currentPage, setCurrentPage] = useState(0); // Track the current page offset

    // Get the subset of blogs based on the current page
    const billsToShow = useMemo(() => {
        return allUser?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    }, [itemsPerPage, currentPage, allUser])
    const pageCount = useMemo(() => Math.ceil(allUser?.length / itemsPerPage)) // Total pages

    const handlePageClick = (event) => {
        setCurrentPage(event.selected); // Update current page when user clicks pagination
    };
    return (
        <>
            <AdminHeader />
            <Grid
                w={"100%"}
                height={"100vh"}

                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"1rem"}
            >
                <AdminSideBar />
                <Box  bgColor={'#ffff'} overflowY={"auto"} p={3}>
                    <Heading children={' User Details'} size={'lg'} m={5} textAlign={'center'} textTransform={'uppercase'} />

                    <TableContainer w={'100%'} rounded='sm' boxShadow='sm' bg={'#ECEFE3'} p={5}>
                        <Table variant={'striped'} colorScheme='pink' size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#7928CA' color='white' p={3}>S.No</Th>
                                    <Th bg='#7928CA' color='white'>Logo</Th>
                                    <Th bg='#7928CA' color='white'>Name</Th>
                                    <Th bg='#7928CA' color='white'>Company Name</Th>
                                    <Th bg='#7928CA' color='white'>Phone</Th>
                                    <Th bg='#7928CA' color='white'>Email</Th>
                                    <Th bg='#7928CA' color='white'>business Since</Th>
                                    <Th bg='#7928CA' color='white'>gst Number</Th>
                                    <Th bg='#7928CA' color='white'>Action</Th>

                                </Tr>
                            </Thead>
                            <Tbody>

                                {billsToShow && billsToShow?.map((item, i) => (
                                    <Tr key={i} >
                                        <Td>{i + 1}</Td>
                                        <Td>
                                            <Avatar name={item?.name} src={item.brandLogo?.url} />
                                        </Td>

                                        <Td>{item?.name}</Td>
                                        <Td>{item?.businessName}</Td>
                                        <Td>{item?.phone}</Td>
                                        <Td>{item?.email}</Td>
                                        <Td>{item?.businessSince}</Td>
                                        <Td>{item?.gstNumber}</Td>
                                        <Td>
                                            <Button ml={'1.5vmax'} size={'sm'}  color={'white'}  colorScheme='red' children={<MdDelete />} />
                                        </Td>

                                    </Tr>
                                ))}

                            </Tbody>
                        </Table>
                    </TableContainer>

                    {/* Pagination Component */}
                    <Box w={'100%'} display="flex" justifyContent="center" mt={6}>
                        <ReactPaginate
                           pageCount={pageCount}
                           pageRangeDisplayed={5}
                           marginPagesDisplayed={2}
                           onPageChange={handlePageClick}
                           previousLabel={'<'}
                           nextLabel={'>'}
                           containerClassName={'pagination'}
                           pageClassName={'page-item'}
                           pageLinkClassName={'page-link'}
                           previousClassName={'page-item'}
                           previousLinkClassName={'page-link'}
                           nextClassName={'page-item'}
                           nextLinkClassName={'page-link'}
                           activeClassName={'active'}
                       />
                    </Box>
                </Box>
            </Grid>
        </>
    )
}

export default AdminUser