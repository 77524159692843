import React from 'react';
import { Text } from '@chakra-ui/react';

const Marque = ({title}) => {
  return (
    <>
      <marquee behavior="smooth" direction="right" scrollamount="10"> 
        <Text
          bgGradient="linear(to-l,#FF0080, #7928CA)"
          bgClip="text"
          fontSize="3xl"
          textAlign={'center'}
          // fontWeight='extrabold'
        >
          {title} 
        </Text>
      </marquee>
    </>
  );
};

export default Marque;
