import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FaPlus, FaUser } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { AfterLoginUserDetails, LoadUser } from "../../../../redux/actions/UserActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from 'country-state-city';

const AfterLogin = () => {
  const [businessName, SetName] = useState('');
  const [userType, setUserType] = useState('');
  const [businessSince, SetBusinessSince] = useState('');
  const [website, SetWebsite] = useState('');
  const [gstNumber, SetGstNumber] = useState('');
  const [brandLogo, setBrandLogo] = useState('');
  // const [selectedLogo, setSelectedLogo] = useState('');

  const [CompanyCity,SetCompanyCity]=useState('')
  const [CompanyState,SetCompanyState]=useState('')
  const [CompanyCountry,SetCompanyCountry]=useState('')
	const [address, SetAddress] = useState('');

  let testCompanyStatecode=CompanyState.split(',')[1]

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.readyState === 2) {
        setBrandLogo(reader.result);
        // setSelectedLogo(file?.name)
      }
    }

  };
  // gstNumber, userType, website, businessSince, brandLogo 
  const submitHandler = async (e) => {
    e.preventDefault()
    await dispatch(AfterLoginUserDetails(businessName, userType, businessSince, website, gstNumber,brandLogo,CompanyCity,CompanyState, CompanyCountry,address ))
    await dispatch(LoadUser())
    navigate('/profile')
    // console.table(businessName, userType, businessSince, website, gstNumber)
  }
  return (
    <>
      <Stack
        boxShadow="lg"
        p="6"
        rounded="md"
        bg="white"
        w={{ base: "90%", md: "70%", lg: "50%" }}
        m={"1vmax auto"}
      >
        <Heading fontSize={"1.7rem"} as={"h2"} children={"Business Details"} />
        <VStack spacing={10} w={"100%"} alignItems={"flex-start"}>
          <form style={{ width: "100%" }} onSubmit={submitHandler} >
            <FormControl>
              <Text
                mb={1}
                fontWeight={600}
                fontSize={"1.1rem"}
                children={"1.Your Name/Business Name*"}
              />
              <Text
                fontSize={".9rem"}
                color={"grey"}
                children={
                  "Official name used across Accounting documents and reposts"
                }
              />
              <Input border={'1px solid rgba(151, 8, 204, .4)'}
                type="text"
                placeholder="If you're a freelancer,add your personal name"
                required
                value={businessName} onChange={(e) => SetName(e.target.value)}
              />
            </FormControl>


            <HStack>
              <FormControl mt={6}>
                <Input
                  type="file"
                  accept="image/*"
                  // display="none"
                  id="fileInput"
                  required
                  onChange={handleFileChange} border={'1px solid rgba(151, 8, 204, .4)'}
                />

                {/* Custom "Choose File" Button */}
                <Button mt={5}
                  as="label"
                  htmlFor="fileInput"
                  colorScheme="pink"
                  variant="solid"
                  size={['sm', 'md']}
                  borderRadius="md"
                  padding="16px 32px"


                  boxShadow="lg"
                  fontSize="lg"
                  cursor="pointer"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <FaPlus style={{ marginRight: "8px" }} /> {/* Icon with margin */}
                  Add Brand or Display name
                </Button>

                {/* Display Selected File Name */}
                {/* <Box mt={3}>
                  <Text color="gray.600" fontSize="md" fontWeight="semibold">
                    {selectedLogo} 
                  </Text>
                </Box> */}
              </FormControl>
            </HStack>

            <RadioGroup defaultValue="2" mt={8}>
              <Text
                mb={1}
                fontWeight={600}
                fontSize={"1.1rem"}
                children={"2.What describes you best?*"}
              />
              <Stack spacing={5} direction={{ base: "column", md: "row" }}>
                <Radio  colorScheme="pink" value="solo" onChange={(e) => setUserType(e.target.value)}>
                  <HStack alignItems="center">
                    <IconButton
                      size="md"
                      colorScheme="black"
                      variant="link"
                      icon={<FaUser />}
                    />
                    <Text as="span" ml={1}>
                      Individual/Freelancer
                    </Text>
                  </HStack>
                </Radio>
                <Radio colorScheme="pink" value="team" onChange={(e) => setUserType(e.target.value)}>
                  <HStack spacing={3} alignItems="center">
                    <IconButton
                      size="md"
                      colorScheme="black"
                      variant="link"
                      icon={<FaUserGroup />}
                    />
                    <Text as="span">Team/Agency/Company</Text>
                  </HStack>
                </Radio>
              </Stack>
            </RadioGroup>

            <FormControl mt={6}>
              <Text
                mb={1}
                fontWeight={600}
                fontSize={"1.1rem"}
                children={"3.In Business Since*"}
              />
              <Text
                fontSize={".9rem"}
                color={"grey"}
                children={
                  "Growing entrepreneur or an established business? Let people know!"
                }
              />

              <Input  color={'grey'} border={'1px solid rgba(151, 8, 204, .4)'}
                type="date"
                placeholder="If you're a freelancer,add your personal name"
                required
                value={businessSince} onChange={(e) => SetBusinessSince(e.target.value)}
              />
            </FormControl>
            <FormControl mt={6}>
              <Text
                mb={1}
                fontWeight={600}
                fontSize={"1.1rem"}
                children={"4.Website"}
              />
              <Text
                fontSize={".9rem"}
                color={"grey"}
                children={
                  "Add your business or work website.Hepls potential clients find you faster."
                }
              />

              <Input border={'1px solid rgba(151, 8, 204, .4)'} type="text" placeholder="Your Work Website" required value={website} onChange={(e) => SetWebsite(e.target.value)} />
            </FormControl>

            <FormControl mt={6}>
              <Text
                mb={1}
                fontWeight={600}
                fontSize={"1.1rem"}
                children={"5.Add GST Number"} />
              <Input border={'1px solid rgba(151, 8, 204, .4)'}
                type="number"
                placeholder="Add GST Number"
                required
                value={gstNumber} onChange={(e) => SetGstNumber(e.target.value)}
              />

            </FormControl>

            <FormControl mt={6}>
              <Text
                mb={1}
                fontWeight={600}
                fontSize={"1.1rem"}
                children={"6.Write address"} />
               <Input
						   	value={address}
							  onChange={e => SetAddress(e.target.value)}
							  type="text"
							  placeholder="Enter Address" border={'1px solid rgba(151, 8, 204, .4)'}	/>
            </FormControl>

            <FormControl mt={6}>
                <FormLabel mb={1} fontWeight={600} fontSize={"1.1rem"}>  7.Country*</FormLabel>
                <Select  color={'grey'} border={'1px solid rgba(151, 8, 204, .4)'} onChange={(e) => { SetCompanyCountry(e.target.value) }} value={CompanyCountry} size={['sm', 'md']} borderRadius={5} >
                    <option style={{ color: 'black' }} value={Country.getCountryByCode('IN').name}>{Country.getCountryByCode('IN').name}</option>
                </Select>
            </FormControl>

            <FormControl mt={6} borderRadius={5}>
             <FormLabel mb={1} fontWeight={600} fontSize={"1.1rem"}>  8.Select State*</FormLabel>
              <Select  color={'grey'} border={'1px solid rgba(151, 8, 204, .4)'}  onChange={(e) => { SetCompanyState(e.target.value) }} value={CompanyState} placeholder='Select State' size={['sm', 'md']}>
                  {
                      State.getStatesOfCountry('IN').map((item, i) => (
                          <option key={i} style={{ color: 'black' }} value={`${item.name},${item.isoCode}`}>{`${item.name}, ${item.isoCode}`} </option>
                      ))
                  }
              </Select>
            </FormControl>

            <FormControl mt={6} borderRadius={5}>
            <FormLabel mb={1} fontWeight={600} fontSize={"1.1rem"} >  9.Select city*</FormLabel>
              <Select   color={'grey'} border={'1px solid rgba(151, 8, 204, .4)'} onChange={(e) => { SetCompanyCity(e.target.value) }}  value={CompanyCity} placeholder='Select City' size={['sm', 'md']}>
                  {
                      City.getCitiesOfState('IN', testCompanyStatecode).map((item, i) => (
                          <option style={{color:'grey'}} key={i} value={item.name}>{item.name}</option>
                      ))
                  }
              </Select>
            </FormControl>

            <HStack m={'1.5vmax 0'} justifyContent={'center'} >
                <Button colorScheme="pink" children={'Add Details'} type={'submit'} />
            </HStack>
          </form>
        </VStack>
      </Stack>
    </>
  );
};

export default AfterLogin;
