import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Heading, HStack, Input, useDisclosure, VStack } from '@chakra-ui/react'
import React  from 'react'
import './style.css'
import { FaBlogger } from 'react-icons/fa6'
import { IoIosPeople, IoMdHome } from 'react-icons/io'
import { FaRegUserCircle, FaUser } from "react-icons/fa";
import { RiDashboardFill, RiShoppingBag3Fill } from 'react-icons/ri'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { RxHamburgerMenu } from "react-icons/rx";
import logo from '../../../img/logo.svg'
import { useSelector } from 'react-redux'
import { IoLogOut } from 'react-icons/io5'
import { GrContact, GrTransaction } from 'react-icons/gr'
import { FaFileInvoice } from "react-icons/fa";
const AdminHeader = () => {

    const navigate =useNavigate()
    const location = useLocation()
    const { isOpen, onOpen, onClose } = useDisclosure()
  

    return (
        <HStack bg='linear-gradient(to right, #fde5d1, #f3c9ed, #f8e1e1)' w={'100%'} color={'green'} p={4} alignItems={'center'} justifyContent={{ base: 'space-between', md: 'space-between' }} display={{ base: 'flex', md: 'flex', lg: 'none' }}>

            <Button colorScheme='purple' variant='ghost' onClick={onOpen}>
                <RxHamburgerMenu size={'xs'} />
            </Button>
            <Box cursor={'pointer'} onClick={()=>navigate('/')} >
                <img src={logo} alt="logo" width={'150px'} />
            </Box>
            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={onClose}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton color={'purple'} />
                    <DrawerHeader bg='linear-gradient(to right, #fde5d1, #f3c9ed, #f8e1e1)' color={'purple'}>Welcome Quickbill.ai</DrawerHeader> 

                    <DrawerBody bg='linear-gradient(to right, #fde5d1, #f3c9ed, #f8e1e1)'>
                        <VStack w={'100%'} alignItems={'flex-start'}>
                        <SideBarItem url={'/'} Icon={IoMdHome} location={location} text={'Home'} />
                        <SideBarItem url={'/admin/dashboard'} Icon={RiDashboardFill} location={location} text={'Dashboard'} />
                        <SideBarItem url={'/admin/invoice'} Icon={FaFileInvoice} location={location} text={'Invoice'} />
                        <SideBarItem url={'/admin/quotation'} Icon={FaFileInvoice} location={location} text={'Quotation'} />
                        <SideBarItem url={'/admin/user'} Icon={FaUser} location={location} text={'User'} />
                        <SideBarItem url={'/admin/contact/user'} Icon={GrContact} location={location} text={'Contact User'} />
                        {/* <SideBarItem url={'admin/transaction'} Icon={GrTransaction } location={location} text={'Total Transaction'} /> */}
                        <SideBarItem url={'/admin/profile'} Icon={FaRegUserCircle} location={location} text={'Admin Profile'} />
                        
                        </VStack>
                    </DrawerBody>
                    <DrawerFooter bg='linear-gradient(to right, #fde5d1, #f3c9ed, #f8e1e1)'>
                    {/* <Button   w={'100%'} rightIcon={<IoLogOut />} children={'Logout'} color={'white'} colorScheme='blue' fontSize={'1.2rem'} mt={'8rem'} borderRadius={'none'} /> */}
                    <div className="logoutWrapper"> 
            <div className="logoutBox">
              <Button  w={'100%'} rightIcon={<IoLogOut />} children={'Logout'} _hover={{bgGradient:'linear(to-l,#FF0080, #7928CA)'}} bgGradient='linear(to-l, #7928CA, #FF0080)' color={'white'} fontSize={'1.2rem'} mt={'8rem'} borderRadius={'none'} />
            </div>
            </div>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

          
        </HStack>
    )
}

export default AdminHeader

const SideBarItem = ({ url, Icon, location, text }) => {
    return (
        <Link style={{ width: '100%' }} placeSelf={'stretch'} to={url} >
            <Button style={{ width: '100%', alignContent: 'flex-start', justifyContent: 'flex-start' }} w={'100%'} size={'lg'} variant='ghost' colorScheme={location.pathname.includes(url) ? 'pink' : 'purple'} leftIcon={<Icon />} children={text} />
        </Link>
    )
}