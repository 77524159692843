import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Stack } from '@chakra-ui/react';
import { Toaster } from 'react-hot-toast'; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import InvoiceTemplate4 from './components/InvoiceTemplates/Template4/InvoiceTemplate4';
import InvoiceTemplate5 from './components/InvoiceTemplates/Template5/InvoiceTemplate5';
import InvoiceTemplate6 from './components/InvoiceTemplates/Template6/InvoiceTemplate6';
import InvoiceTemplate7 from './components/InvoiceTemplates/Template7/InvoiceTemplate7';
import InvoiceTemplate8 from './components/InvoiceTemplates/Template8/InvoiceTemplate8';

import Loader from './components/Loader/Loader';
import { AdminLoadAllContacedUser, AdminLoadAllUser, LoadUser } from './redux/actions/UserActions';
import { AdminGetAllInvoiceData, GetAllInvoice } from './redux/actions/InvoiceActions';
import GSTInvoiceTemplates from './components/InvoiceGenerateNew/GSTInvoiceTemplate/GSTInvoiceTemplates';
import QuotationGenerator from './components/InvoiceGenerateNew/QuotationGenerator/QuotationGenerator';
import AfterLogin from './components/User/NewUser/AfterLogin/AfterLogin';
import Dashboard from './components/Admin/Dashboard/Dashboard';
import AdminInvoice from './components/Admin/AdminInvoice/AdminInvoice';
import AdminUser from './components/Admin/AdminUser/AdminUser';
import AdminContactUser from './components/Admin/AdminContactUser/AdminContactUser';
import AdminProfile from './components/Admin/AdminProfile/AdminProfile';
import AdminQuotation from './components/Admin/AdminQuotation/AdminQuotation';
import { AdminGetAllQuotationData } from './redux/actions/QuotationActions';

const Quote = lazy(() => import('./components/Quote/Quote'));
const OurProducts = lazy(() => import('./components/OurProducts/OurProducts'));
const LatestBlog = lazy(() => import('./components/LatestBlog/LatestBlog'));


const Term = lazy(() => import('./components/Term&Conditions/Term'));
const Privacy = lazy(() => import('./components/Privacy&Policy/Privacy'));

const NewLogin = lazy(() => import('./components/User/NewUser/NewLogin/NewLogin'));

const NewSignUp = lazy(() => import('./components/User/NewUser/NewSignUp/NewSignUp'));

const Invoice_generator = lazy(() => import('./components/InvoiceGenerateNew/Invoice_generator'));

const Why_quick = lazy(() => import('./components/Why_quick/Why_quick'));
const TemplatesSlider = lazy(() => import('./components/TemplateSlider/Templates'));

const Home = lazy(() => import('./components/Home/Home'));
const Header = lazy(() => import('./components/Header/Header'));
const NewFooter = lazy(() => import('./components/Footer/Footer'));
const About = lazy(() => import('./components/About/About'));
const ContactForm = lazy(() =>import('./components/ContactForm/ContactForm'));
const Support = lazy(() => import('./components/Support/Support'));

const Profile = lazy(() => import('./components/User/Profile'));
const ForgetPassword = lazy(() =>import('./components/ForgetPassword/ForgetPassword'));
const ResetPassword = lazy(() =>import('./components/ForgetPassword/ResetPassword'));

const InvoiceTemplateOne = lazy(() =>import('./components/InvoiceTemplates/InvoiceTemplateOne'));
const InvoiceTemplateTwo = lazy(() => import('./components/InvoiceTemplates/InvoiceTemplateTwo'));
const InvoiceTemplateThree = lazy(() => import('./components/InvoiceTemplates/InvoiceTemplateThree'));

const InvoiceGeneratePage = lazy(() =>import('./components/InvoiceGeneratePage/InvoiceGeneratePage'));

const PageNotFound = lazy(() =>import('./components/PageNoFound/PageNotFound'));



function App() {
  const { user, loading, isAuthenticated } = useSelector(
    state => state.userContainer
  );
	const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(LoadUser())
    dispatch(GetAllInvoice())
    dispatch(AdminLoadAllUser())
    dispatch(AdminGetAllInvoiceData())
    dispatch(AdminGetAllQuotationData())
    dispatch(AdminLoadAllContacedUser())
  
  },[])
  return (
    <BrowserRouter>
      {loading ? (
        <Stack w={'100%'} h={'100vh'} justifyContent={'center'} alignItems={'center'} >
            {/* <CircularProgress thickness="4px" size={'20vmax'} isIndeterminate color='purple' /> */}
            <Loader />
        </Stack>
      ) : (
        <>
          <Suspense fallback={
            <Stack w={'100%'} h={'100vh'} justifyContent={'center'} alignItems={'center'}  >
                {/* <CircularProgress thickness="4px" size={'20vmax'} isIndeterminate color='purple.300' /> */}
                <Loader />
            </Stack>
          }
          >
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/login" element={<NewLogin />} />

              {/* <Route path="/signup" element={<SignUp />} /> */}
              <Route path="/signup" element={<NewSignUp />} />

              <Route path="/forgetpassword" element={<ForgetPassword />} />
              <Route path="/resetpassword/:token" element={<ResetPassword />} />

              {/* <Route path="/profile" element={ <Profile /> } /> */}
              <Route path="/profile" element={isAuthenticated ? <Profile /> : <NewSignUp />} />

              <Route path="/invoiceone" element={<InvoiceTemplateOne />} />
              <Route path="/invoicetwo" element={<InvoiceTemplateTwo />} />
              <Route path="/invoicethree" element={<InvoiceTemplateThree />} />
              <Route path="/invoicefour" element={<InvoiceTemplate4 />} />
              <Route path="/invoicefive" element={<InvoiceTemplate5 />} />
              <Route path="/invoicesix" element={<InvoiceTemplate6 />} />
              <Route path="/invoiceseven" element={<InvoiceTemplate7 />} />
              <Route path="/invoiceeight" element={<InvoiceTemplate8 />} />

              <Route path="/whyquick" element={<Why_quick />} />
              <Route path="/templates" element={<TemplatesSlider />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/term&condition" element={<Term />} />
              <Route path="/ourproducts" element={<OurProducts />} />
              <Route path="/latestblog" element={<LatestBlog />} />
              <Route path="/quote" element={<Quote />} />
              <Route path="/afterlogin" element={<AfterLogin />} />

              {/* <Route path="/tryfree2" element={<InvoiceGeneratePage /> } /> */}
              {/* <Route path="/tryfree" element={<Invoice_generator />} /> */}
              {/* <Route path="/tryfree" element={isAuthenticated ? <Invoice_generator /> : <NewSignUp />} /> */}
              <Route path="/tryfree" element={isAuthenticated ? <GSTInvoiceTemplates /> : <NewSignUp />} />
              <Route path="/quotationgenerator" element={isAuthenticated ? <QuotationGenerator /> : <NewSignUp />} />
              {/* <Route path="/GSTInvoiceTemplates" element={<GSTInvoiceTemplates />} /> */}
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/about" element={<About />} />
              <Route path="/support" element={<Support />} />

              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/invoice" element={<AdminInvoice />} />
              <Route path="/admin/quotation" element={<AdminQuotation />} />
              <Route path="/admin/user" element={<AdminUser />} />
              <Route path="/admin/contact/user" element={<AdminContactUser />} />
              <Route path="/admin/profile" element={<AdminProfile />} />
           
  
              <Route path="/*" element={<PageNotFound />} />
            </Routes>
            <NewFooter />
          </Suspense>
        </>
      )}
      <Toaster />
    </BrowserRouter>
  );
}

export default App;
