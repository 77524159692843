import { Avatar, Box, Card, CardBody, Grid, HStack, Heading, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import SideBar from "../AdminSideBar/AdminSideBar";
import Header from "../AdminHeader/AdminHeader";
import { FaUserCircle } from "react-icons/fa";
import { IoIosPeople } from 'react-icons/io'
import { RiShoppingBag3Fill } from 'react-icons/ri'


import './Dashboard.css'
// import { BarChart, DoughnutChart } from "../Charts/Chart";
import { BiMaleFemale } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { GrTransaction } from "react-icons/gr";
import { FaFileInvoice, FaMoneyBillTrendUp } from "react-icons/fa6";
import { AdminGetAllInvoiceData, GetAllInvoice } from "../../../redux/actions/InvoiceActions";
import { AdminLoadAllContacedUser, AdminLoadAllUser, LoadUser } from "../../../redux/actions/UserActions";
import { AdminGetAllQuotationData } from "../../../redux/actions/QuotationActions";



const Dashboard = () => {

const { allUser, loading, allContacedUser } = useSelector(
    state => state.userContainer
);
const { adminQuotations } = useSelector(
  state => state.quotationContainer
);
const { adminInvoice } = useSelector(
  state => state.invoiceContainer
);
  const userDetails = [ 
    { name: 'Robin zudu',comapny:'Cyvero technologies', phone: '123456789',email: 'xyz@gmail.com',invoice:'2',date:'23/11/2024'},
    { name: 'Robin zudu',comapny:'Cyvero technologies', phone: '123456789',email: 'xyz@gmail.com',invoice:'2',date:'23/11/2024'},
    { name: 'Robin zudu',comapny:'Cyvero technologies', phone: '123456789',email: 'xyz@gmail.com',invoice:'2',date:'23/11/2024'},
    { name: 'Robin zudu',comapny:'Cyvero technologies', phone: '123456789',email: 'xyz@gmail.com',invoice:'2',date:'23/11/2024'},

]
  const dispatch=useDispatch()
  // useEffect(()=>{
  //   dispatch(LoadUser())
  //   dispatch(GetAllInvoice())
  //   dispatch(AdminLoadAllUser())
  //   dispatch(AdminGetAllInvoiceData())
  //   dispatch(AdminGetAllQuotationData())
  //   dispatch(AdminLoadAllContacedUser())
  
  // },[])
  return (
    <>
      <Header />
      <Grid w={'100%'} height={'100vh'} gridTemplateColumns={{ base: '1fr', md: '1fr', lg: '1fr 4fr' }} gap={'1rem'} >
        <SideBar />
        <Box bgColor={'#ffff'}  overflowY={'auto'} p={3} >

          <Heading children={'Dashboard'} size={'lg'} m={5} textAlign={'center'} textTransform={'uppercase'} />
          <HStack  w={'100%'} alignItems={'center'} justifyContent={'center'} spacing={8} flexWrap={'wrap'}>
            <Card boxShadow={'lg'} maxW={'290px'} w={'100%'} bg={'linear-gradient(135deg, rgba(26, 41, 255, .89) 0%, #949cfb 100%)'} >
              <CardBody w={'100%'}>
                <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                  <Heading children={'total users'} fontSize={'1.5rem'} color={'white'} opacity={'.5'} textTransform={'capitalize'} />
                  <FaUserCircle fontSize={'3rem'} color={'white'} opacity={'.3'} />
                </HStack>
                <Text children={allUser?.length} fontSize={'2rem'} fontWeight={'700'} color={'white'} />
              </CardBody>
            </Card>
            <Card boxShadow={'lg'} maxW='290px' w={'100%'} bg={'#f069b4'} >
              <CardBody w={'100%'}>
                <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                  <Heading children={'Invoice'} fontSize={'1.5rem'} color={'white'} opacity={'.5'} textTransform={'capitalize'} />
                  <FaFileInvoice fontSize={'3rem'} color={'white'} opacity={'.3'} />
                </HStack>
                <Text children={adminInvoice?.length} fontSize={'2rem'} fontWeight={'700'} color={'white'} />
              </CardBody>
            </Card>
            <Card boxShadow={'lg'} maxW='290px' w={'100%'} bg={'linear-gradient(135deg, #fb2c84 0%, #8a3eff 100%)'}>
              <CardBody w={'100%'}>
                <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                  <Heading children={'Quotation'} fontSize={'1.5rem'} color={'white'} opacity={'.5'} textTransform={'capitalize'} />
                  <FaMoneyBillTrendUp fontSize={'3rem'} color={'white'} opacity={'.3'} />
                </HStack>
                <Text children={adminQuotations?.length} fontSize={'2rem'} fontWeight={'700'} color={'white'} />
              </CardBody>
            </Card>
            <Card boxShadow={'lg'} maxW='290px' w={'100%'} bg={'linear-gradient(135deg, #ff9c1d 0%, #ff428d 100%)'} >
              <CardBody w={'100%'}>
                <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                  <Heading children={'Contact User'} fontSize={'1.5rem'} color={'white'} opacity={'.5'} textTransform={'capitalize'} />
                  <GrTransaction fontSize={'3rem'} color={'white'} opacity={'.3'} />
                </HStack>
                <Text children={allContacedUser?.length} fontSize={'2rem'} fontWeight={'700'} color={'white'} />
              </CardBody>
            </Card>

          </HStack>


          <Stack w={'95%'} m={'auto'} spacing={10} alignItems={'center'} justifyContent={'center'} mt={10} mb={5} direction={{ base: 'column', md: 'row' }}>

            {/* Recent Astrologer */}
            <Box  w={{ base: '100%', md: '50%' }} >
              <Heading children={'Recent Invoice'} fontSize={'1.5rem'} />

              {
                adminQuotations && adminQuotations.slice(-3).reverse().map((item) => (
                    <HStack bg={'white'} boxShadow='lg' w={'100%'}  p={3} alignItems={'center'} mt={5} mb={5}  rounded='md' >
                    <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                        <VStack alignItems={'flex-start'} ml={3}>
                            <Heading children={'Avinash Singh'} fontSize={'1rem'} />
                            <Text children={'avi@gmail.com'} />
                        </VStack>
                    </HStack>
                ))
              }

            </Box>
            <Box  w={{ base: '100%', md: '50%' }} >
              <Heading children={'Recent Quotation'} fontSize={'1.5rem'} />

              {
                adminInvoice && adminInvoice.slice(-3).reverse().map((item) => (
                  <HStack bg={'white'} boxShadow='lg' w={'100%'}  p={3} alignItems={'center'} mt={5} mb={5}  rounded='md' >
                  <Avatar name='Kent Dodds' src='https://bit.ly/kent-c-dodds' />
                      <VStack alignItems={'flex-start'} ml={3}>
                          <Heading children={'Avinash Singh'} fontSize={'1rem'} />
                          <Text children={'avi@gmail.com'} />
                      </VStack>
                  </HStack>
                  
                ))
              }

            </Box>
          </Stack>



          <Stack w={'95%'} m={'auto'}   spacing={10} justifyContent={'center'} alignItems={'center'}  mt={8} mb={5} direction={{ base: 'column', md: 'row'}}>
           

            <Box w={{base:'100%',md:'100%',lg:'100%'}}>
              <Heading children={'Recent User Details'} fontSize={'1.5rem'} mb={5}/>

              <TableContainer  w={'100%'} rounded='sm' boxShadow='sm' bg={'#ECEFE3'} p={5}>
                        <Table variant={'striped'} colorScheme='pink' size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#7928CA' color='white' p={3}>S.No</Th>
                                    <Th bg='#7928CA' color='white'>Logo</Th>
                                    <Th bg='#7928CA' color='white'>Name</Th>
                                    <Th bg='#7928CA' color='white'>Company_Name</Th>
                                    <Th bg='#7928CA' color='white'>Phone</Th>
                                    <Th bg='#7928CA' color='white'>Email</Th>
                                    <Th bg='#7928CA' color='white'>Bill Generate</Th>
                                    <Th bg='#7928CA' color='white'>Bill_date</Th>
                                    {/* <Th bg='#162536' color='white'>Message</Th> */}
                               
                                </Tr>
                            </Thead>
                            <Tbody>

                            {allUser && allUser?.slice(-10).map((item,i) => (
                                       <Tr key={i} >
                                    <Td>{i+1}</Td>
                                     <Td>
                                     <Avatar name={item?.name} src={item?.brandLogo?.url}/>
                                    </Td>
                          
                                     <Td>{item?.name}</Td>
                                     <Td>{item?.comapny}</Td>
                                     <Td>{item?.phone}</Td>
                                     <Td>{item?.email}</Td>
                                     <Td>{item?.invoice}</Td>
                                     <Td>{item?.date}</Td>
                                   
                                 </Tr>
                                 ))}

                            </Tbody>
                        </Table>
                    </TableContainer>

                    
            </Box>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};


export default Dashboard;
