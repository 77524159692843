import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { Box, Button, Heading, HStack, Image, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

const InvoiceTemplate5 = ({
    BillData, totalAmountWithTax, totalAmount, totalSgstAmount, totalCgstAmount, invoiceItem, invoiceLogo, totalIgstAmount, taxMode,
    invoiceName, invoiceNumber, invoiceDate, invoiceDueDate,
    CompanyName, YourName, CompanyGSTTin, CompanyCity, CompanyState, CompanyCountry,
    ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry
}) => {
    const [hidePrintBtn, setHidePrintBtn] = useState(false)
    const contentRef = useRef();

    const dispatch = useDispatch()
    const navigate = useNavigate()
 // Function to handle print action and store quotation
 const reactToPrintFn = useReactToPrint({
    contentRef,
    onBeforePrint: async () => {
    await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax,totalAmount, invoiceDate, invoiceItem, ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry))

        // alert('api for quotation')
        // console.log('onBeforePrint')
    },
    onAfterPrint: () => {
        navigate('/profile'); 
        // console.log('onAfterPrint')
    }
});
    const InvoiceDataHandler = async () => {
        setHidePrintBtn(true)

        await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
        window.print()
        navigate('/profile')
    }
    return (


        <>
        <Box p={'5px'} mt={'4vmax'} ml={'1vmax'} ref={contentRef}>

            <VStack>
                <Image src={invoiceLogo} alt={'invoiceLogo'} w={'20%'} />
                <Heading fontSize={'1.5rem'} children={`${CompanyName} `} />
                <Text fontSize={'1rem'}>{`GST-Tin${CompanyGSTTin} ${CompanyCity} ${CompanyState} ${CompanyCountry}`}</Text>
            </VStack>
            <HStack w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack w={'50%'} alignItems={'flex-start'}>
                    <Heading fontSize={'1.5rem'}>Issue to: <span style={{ fontWeight: '500', fontSize: '1.2rem' }}>{ClientCompanyName}</span> </Heading>
                    <Text>{ClientName}</Text>
                    <Text>{`GST-Tin${ClientGSTTin} ${ClientCity} ${ClientState} ${ClientCountry}`}</Text>
                </VStack>

                <VStack w={'50%'} alignItems={'flex-end'}>
                    <HStack>
                        <VStack alignItems={'flex-start'}>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>invoice no:</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>date:</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>due date:</Text>


                        </VStack>
                        <VStack alignItems={'flex-end'}>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>{invoiceNumber}</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>{invoiceDate}</Text>
                            <Text fontWeight={'600'} textTransform={'capitalize'}>{invoiceDueDate}</Text>
                        </VStack>
                    </HStack>
                   

                </VStack>
            </HStack>


            <TableContainer>
                <Table>
                    <Thead>

                        <Tr >
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}>DESCRIPTION</Th>
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}> QTY</Th>
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}>PRICE</Th>
                            <Th fontSize={'1.1rem'} style={{ backgroundColor: "#DCCEAC" }}>TOTAL</Th>
                        </Tr>


                    </Thead>
                    <Tbody>
                        {

                            BillData && BillData.map((Item, key) => (
                                <Tr key={key} >
                                    <Td style={{ fontWeight: "400", textTransform: 'capitalize' }}>{Item.itemDes}</Td>
                                    <Td style={{ fontWeight: "400" }}>{Item.qty}</Td>
                                    <Td style={{ fontWeight: "400" }}>{Item.rate}</Td>
                                    <Td style={{ fontWeight: "400" }}>{Item.amount}</Td>

                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>

            <Stack>
                {/* <HStack w={'90%'} m={'auto'} alignItems={'center'} justifyContent={'space-between'} >
                    <Text style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                        Subtotal
                    </Text>
                    <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>{`${parseFloat(totalAmount).toFixed(2)}`}</p>
                </HStack> */}
                <VStack w={'96%'} alignItems={'flex-end'} >
                    <HStack  >
                        <Text style={{ fontSize: "1.2rem", fontWeight: "700" }}>
                            Subtotal :
                        </Text>
                        <p style={{ fontSize: "1.2rem", fontWeight: "700" }}>{`${parseFloat(totalAmount).toFixed(2)}`}</p>
                    </HStack>
                    <HStack >
                        <VStack>
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px", fontWeight: '700' }} isNumeric>SGST : </p>}
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px", fontWeight: '700' }} isNumeric>CGST : </p>}
                        </VStack>
                        <VStack>
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px" }} isNumeric>{` ${totalSgstAmount}`}</p>}
                            {taxMode === 'CGST_SGST' && <p style={{ marginRight: "10px" }} isNumeric>{` ${totalCgstAmount}`}</p>}
                        </VStack>
                    </HStack>

                    <HStack>
                        {taxMode === 'IGST' && <p style={{ marginRight: "10px", fontWeight: '700' }} isNumeric>IGST : </p>}
                        {taxMode === 'IGST' && <p style={{ marginRight: "10px" }} isNumeric>{` ${totalIgstAmount}`}</p>}
                    </HStack>


                    {/* <p>{totalSgstAmount+totalCgstAmount}</p> */}

                </VStack>
            </Stack>

            <HStack w={'96%'} alignItems={'flex-end'} justifyContent={'flex-end'} >
                <p style={{ marginRight: "10px", fontWeight: '700' }}>Total:</p>
                <p style={{ marginRight: "10px" }}>{`${parseFloat(totalAmountWithTax).toFixed(2)}`}</p>
            </HStack>

            <HStack mt={3} w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <VStack >
                    <h4>Company signature</h4>

                </VStack>

                <VStack >
                    <h4>Customer signature</h4>
                </VStack>
            </HStack>
            {/* {
                hidePrintBtn ? null : <Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={() => InvoiceDataHandler()} />
            } */}
        </Box>
        <Button children={'Print'} variant={'solid'} colorScheme={"whatsapp"} onClick={reactToPrintFn} />

        </>
    )
}

export default InvoiceTemplate5