import React, { useRef, useState } from 'react'
import './InvoiceTemplate7.css'
import phone from '../../../img/phone.jpeg';
import mail from '../../../img/mail.jpeg';
import chrome from '../../../img/chrome.jpeg';
import bgpic from '../../../img/bgpic.jpeg';
import bgpic2 from '../../../img/bgpic2.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreateInvoice } from '../../../redux/actions/InvoiceActions';
import { Box, Button, Table } from '@chakra-ui/react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';


const InvoiceTemplate7 = ({
    BillData,totalAmountWithTax,totalAmount,totalSgstAmount,totalCgstAmount,invoiceItem,invoiceLogo,totalIgstAmount,taxMode,
    invoiceName,invoiceNumber,invoiceDate,invoiceDueDate,
    CompanyName,YourName,CompanyGSTTin,CompanyCity,CompanyState,CompanyCountry,
    ClientCompanyName,ClientName,ClientGSTTin,ClientCity,ClientState,ClientCountry
}) => {
  const [hidePrintBtn,setHidePrintBtn]=useState(false)

  const dispatch=useDispatch()
  const navigate=useNavigate()
  const { user} = useSelector( state => state.userContainer );
  const contentRef = useRef();

 // Function to handle print action and store quotation
 const reactToPrintFn = useReactToPrint({
  contentRef,
  onBeforePrint: async () => {
    await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax,totalAmount, invoiceDate, invoiceItem, ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry))

      // alert('api for quotation')
      // console.log('onBeforePrint')
  },
  onAfterPrint: () => {
      navigate('/profile'); 
      // console.log('onAfterPrint')
  }
});
  const InvoiceDataHandler=async()=>{    
    setHidePrintBtn(true)  

      await dispatch(CreateInvoice(invoiceNumber, totalAmountWithTax, invoiceDate, invoiceItem, ClientCompanyName))
      window.print()
      navigate('/profile')
  }
      return (
        <>
        <Box p={'5px'} mt={'4vmax'} ml={'1vmax'} ref={contentRef}>

        <div className="invoicePayment">
          <div className="invoice-bgpic" >
            <img src={bgpic} alt="bgpic" />
          </div>
    
          <div className="invoice-head">
            <div className="invoice-head-logo">
              <img src={invoiceLogo} alt="invoiceLogo" />
              <h4>{CompanyName}</h4>
            </div>
    
            <div className="invoice-head-box">
              <h2>INVOICE</h2>
              <div className="invoice-box-list">
                <div className="invoice-no">
                  <li>Invoice No:</li>
                  <li>Due Date:</li>
                  <li>Invoice Date:</li>
                </div>
                <div className="invoice-date">
                  <li>{invoiceNumber}</li>
                  <li>{invoiceDueDate}</li>
                  <li>{invoiceDate}</li>
                </div>
              </div>
            </div>
          </div>
    
          <div className="invoice-to">
            <h3 style={{ fontSize: "1.1rem", fontWeight: "600", fontFamily: "sans-serif" }}>INVOICE TO:</h3>
            <li style={{ fontSize: "1.3rem", fontWeight: "700", fontFamily: "sans-serif", color: 'black' }}>{ClientName}</li>
            <li style={{ fontSize: "1.2rem", fontWeight: "600", color: "rgb(80, 77, 77)" }}>{`GST-Tin${ClientGSTTin} ${ClientCity} ${ClientState} ${ClientCountry}`}</li>
    
            <div className="invoice-bottom">
              <div className="invoice-details">
                <li>Phone No:</li>
                <li>Email:</li>
                <li>Address:</li>
              </div>
              <div className="invoice-details">
                <li>{user?.phone}</li>
                <li>{user?.email}</li>
                <li>123 Anywere St,Any city</li>
              </div>
            </div>
    
    
          </div>
    
          <div className="invoice-bill-table mt-3">
            <Table responsive="md" >
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#a70000", color: "white", padding: "1rem", borderRadius: "10px 0 0 10px" }}>DESCRIPTION</th>
                  <th style={{ backgroundColor: "#a70000", color: "white", padding: "1rem" }}> PRICE </th>
                  <th style={{ backgroundColor: "#a70000", color: "white", padding: "1rem" }}> QTY</th>
                  <th style={{ backgroundColor: "#a70000", color: "white", padding: "1rem", borderRadius: "0px 10px 10px 0px" }}>SUBTOTAL</th>
                </tr>
              </thead>
              <tbody>
                {
    
                    BillData && BillData.map((Item, key) => (
                    <tr key={key} >
                      <td style={{ borderBottom: "2px solid #a70000", padding: "1rem" }}>{Item.itemDes}</td>
                      <td style={{ borderBottom: "2px solid #a70000", padding: "1rem" }}>{Item.rate}</td>
                      <td style={{ borderBottom: "2px solid #a70000", padding: "1rem" }}>{Item.qty}</td>
                      <td style={{ borderBottom: "2px solid #a70000", padding: "1rem" }}>{Item.amount}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </div>
    
          <div className="invoice-condition-para" style={{marginTop:'10px'}}>
            <div className="term-condition">
              <h3>TERMS AND CONDITIONS</h3>
              <p>Please send payment within 30 days of receiving this invoice.There will be 10%  interest  charge per month on late invoice.</p>
            </div>
    
            <div className="invoice-amount">
              <div className="amount-des">
                <p>Sub-total</p>
                {taxMode === 'CGST_SGST' && <p isNumeric>SGST</p>}
                {taxMode === 'CGST_SGST' && <p isNumeric>CGST</p>}
                {taxMode === 'IGST' && <p isNumeric>IGST</p>}
                <p style={{ backgroundColor: "#a70000", color: "white", padding: "5px", borderRadius: "5px 0 0 5px" }}>Total</p>
              </div>
              <div className="amount-price">
                <p>{totalAmount}</p>
                {taxMode === 'CGST_SGST' && <p isNumeric>{totalSgstAmount}</p>}
                {taxMode === 'CGST_SGST' && <p isNumeric>{totalCgstAmount}</p>}
                {taxMode === 'IGST' && <p isNumeric>{totalIgstAmount}</p>}
                <p style={{ backgroundColor: "#a70000", color: "white", marginLeft: "0", padding: "5px", borderRadius: "0px 5px 5px 0px" }}>{totalAmountWithTax}</p>
              </div>
            </div>
          </div>
    
    
          <div className="thank-invoice-section">
            <div className="invoice-contact">
              <h3>Thank You For your business</h3>
              <div className="social-items">
                <div className="social-icons">
                  <li><a href=""><img src={phone} alt="" /></a></li>
                  <li><a href=""><img src={mail} alt="" /></a></li>
                  <li><a href=""><img src={chrome} alt="" /></a></li>
                </div>
    
                <div className="social-text">
                  <li>{user?.phone}</li>
                  <li>{user?.email}</li>
                  <li>123 Anywere St,Any city</li>
                </div>
              </div>
            </div>
    
            <div className="right-contact">
              <div className="upperline"></div>
              <h2>{YourName}</h2>
              <p>company ceo</p>
            </div>
          </div>
          <div className="invoice-bgpic" id='rev'>
            <img src={bgpic2} alt="bgpic2"/>
          </div>
        </div>
        {/* {
        hidePrintBtn?null:<Button children={'print'} variant={'solid'} colorScheme={"whatsapp"} onClick={()=>InvoiceDataHandler()} />
      } */}
        </Box>

        <Button children={'Print'} variant={'solid'} colorScheme={"whatsapp"} onClick={reactToPrintFn} />

        </>
      )
}

export default InvoiceTemplate7