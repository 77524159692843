import toast from 'react-hot-toast';
import { server } from '../../index';
import axios from 'axios';

export const CreateQuotation = (quotationNumber,totalAmountWithTax, totalAmount, quotationDate, quotationItem, ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry) => async dispatch => { 
  try {
    dispatch({ type: 'QuotationRequest' });
    console.table([ quotationNumber,totalAmountWithTax, totalAmount, quotationDate, quotationItem, ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry])
    const { data } = await axios.post(
      `${server}/quotation/create`,
      { quotationNumber,totalAmountWithTax, totalAmount, quotationDate, quotationItem, ClientCompanyName, ClientName, ClientGSTTin, ClientCity, ClientState, ClientCountry},
      {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      }
    );

    dispatch({
      type: 'QuotationSuccess',
      payload: data
    });
    toast.success(data.message)

  } catch (error) {
    dispatch({
      type: 'QuotationFail',
      payload:error.message,
    });
    toast.error(error.message)

  }
};


export const GetAllQuotation = () => async dispatch => { 
    try {
      dispatch({ type: 'GetAllQuotationRequest' });
  
      const { data } = await axios.get(
        `${server}/quotation/get/all`,       
        {       
          withCredentials: true,
        }
      );
  
      dispatch({
        type: 'GetAllQuotationSuccess',
        payload: data
      });
    // toast.success(data.message)

    } catch (error) {
      dispatch({
        type: 'GetAllQuotationFail',
        payload:error.message,
      });
    // toast.error(error.message)

    }
 };


export const DeleteQuotation = (quotation_id) => async dispatch => { 
    try {
      dispatch({ type: 'DeleteQuotationRequest' });
  
      const { data } = await axios.delete(
        `${server}/quotation/delete/${quotation_id}`,        
        {       
          withCredentials: true,
        }
      );
  
      dispatch({
        type: 'DeleteQuotationSuccess',
        payload: data
      });
    toast.success(data.message)

    } catch (error) {
      dispatch({
        type: 'DeleteQuotationFail',
        payload:error.message,
      });
    toast.error(error.message)

    }
 };

 export const AdminGetAllQuotationData = () => async dispatch => { 
  try {
    dispatch({ type: 'AdminGetAllQuotationDataRequest' });

    const { data } = await axios.get(
      `${server}/quotation/admin/get/all`,       
      {       
        withCredentials: true,
      }
    );

    dispatch({
      type: 'AdminGetAllQuotationDataSuccess',
      payload: data.quotations
    });
  // toast.success(data.message)

  } catch (error) {
    dispatch({
      type: 'AdminGetAllQuotationDataFail',
      payload:error.message,
    });
  // toast.error(error.message)

  }
};

export const AdminDeleteQuotation = (quotation_id) => async dispatch => { 
  try {
    dispatch({ type: 'AdminDeleteQuotationRequest' });

    const { data } = await axios.delete(
      `${server}/quotation/admin/delete/${quotation_id}`,        
      {       
        withCredentials: true,
      }
    );

    dispatch({
      type: 'AdminDeleteQuotationSuccess',
      payload: data
    });
  toast.success(data.message)

  } catch (error) {
    dispatch({
      type: 'AdminDeleteQuotationFail',
      payload:error.message,
    });
  toast.error(error.message)

  }
};