import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Grid, Heading, HStack, IconButton, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react'
import ReactPaginate from 'react-paginate';
import { MdDelete } from 'react-icons/md';
import { DeleteInvoice, GetAllInvoice } from '../../redux/actions/InvoiceActions';


const UserInvoice = () => {
    const { bills, loading } = useSelector(
        state => state.invoiceContainer.invoice
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const itemsPerPage = 5; // Set number of items per page
    const [currentPage, setCurrentPage] = useState(0); // Track the current page offset

    // Get the subset of blogs based on the current page
    const billsToShow = useMemo(() => {
        return bills.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    }, [itemsPerPage, currentPage, bills])
    const pageCount = useMemo(() => Math.ceil(bills.length / itemsPerPage)) // Total pages

    const handlePageClick = (event) => {
        setCurrentPage(event.selected); // Update current page when user clicks pagination
    };

    const deleteInvoice= async (id) => {
        await dispatch(DeleteInvoice(id))
        await dispatch(GetAllInvoice())
    }

    return (
        <>
            {
                bills && bills.length >= 1 ? (
                    <TableContainer p={5} w={'100%'}>
                        <Table size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#7928CA' color='white' p={3}>S.No</Th>
                                    {/* <Th bg='#162536' color='white'>Profile</Th> */}
                                    <Th bg='#7928CA' color='white'>CompanyName</Th>
                                    <Th bg='#7928CA' color='white'>ClientName</Th>
                                    <Th bg='#7928CA' color='white'>Price</Th>
                                    <Th bg='#7928CA' color='white'>totalAmountWithTax</Th>
                                    <Th bg='#7928CA' color='white'>ClientGSTTin</Th>
                                    <Th bg='#7928CA' color='white'>Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {billsToShow && billsToShow?.map((item, i) => (
                                    <Tr key={item._id} >
                                        <Td>{currentPage * itemsPerPage + i + 1}</Td>
                                        {/* <Td>
                                            <Avatar name={item?.userName} src={item?.userAvatar} />
                                        </Td> */}
                                        <Td>{item?.ClientCompanyName}</Td>
                                        <Td>{item?.ClientName}</Td>
                                        <Td>{item?.totalAmount}</Td>
                                        <Td>{item?.totalAmountWithTax}</Td>
                                        <Td>{item?.ClientGSTTin}</Td>
                                        <Td>
                                            <Button ml={'1.5vmax'} size={'sm'} onClick={() => deleteInvoice(item._id)} color={'white'}  colorScheme='red' children={<MdDelete />} />
                                        </Td>
                                    </Tr>
                                ))}

                            </Tbody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Text textAlign={'center'} children={' no Invoice'} />

                )
            }
            {/* Pagination Component */}
            <Box w={'100%'} display="flex" justifyContent="center" mt={6}>
                <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageClick}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
            </Box>
        </>
    )
}

export default UserInvoice