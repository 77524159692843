import React, { useEffect, useMemo, useState } from 'react'
import AdminHeader from '../AdminHeader/AdminHeader'
import AdminSideBar from '../AdminSideBar/AdminSideBar'
import { Box, Grid, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { AdminLoadAllContacedUser } from '../../../redux/actions/UserActions'
import { useDispatch, useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'


const AdminContactUser = () => {

    const userDetails = [
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
    ]
    const { loading, allContacedUser } = useSelector(
        state => state.userContainer
    );

    const dispatch = useDispatch()

    const itemsPerPage = 5; // Set number of items per page
    const [currentPage, setCurrentPage] = useState(0); // Track the current page offset

    // Get the subset of blogs based on the current page
    const billsToShow = useMemo(() => {
        return allContacedUser?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    }, [itemsPerPage, currentPage, allContacedUser])
    const pageCount = useMemo(() => Math.ceil(allContacedUser?.length / itemsPerPage)) // Total pages

    const handlePageClick = (event) => {
        setCurrentPage(event.selected); // Update current page when user clicks pagination
    };
    return (
        <>
            <AdminHeader />
            <Grid
                w={"100%"}
                height={"100vh"}

                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"1rem"}
            >
                <AdminSideBar />
                <Box  bgColor={'#ffff'} overflowY={"auto"} p={3}>
                    <Heading children={'Contact User'} size={'lg'} m={5} textAlign={'center'} textTransform={'uppercase'} />

                    <TableContainer w={'100%'} rounded='sm' boxShadow='sm' bg={'#ECEFE3'} p={5}>
                        <Table variant={'striped'} colorScheme='pink' size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#7928CA' color='white' p={3}>S.No</Th>
                                    <Th bg='#7928CA' color='white'>Name</Th>
                                    <Th bg='#7928CA' color='white'>Email</Th>
                                    <Th bg='#7928CA' color='white'>Phone</Th>
                                    <Th bg='#7928CA' color='white'>Date</Th>
                                    <Th bg='#7928CA' color='white'>Message</Th>

                                </Tr>
                            </Thead>
                            <Tbody>

                                {
                                    allContacedUser && allContacedUser.map((item, i) => (
                                        <Tr key={i} >
                                            <Td>{i + 1}</Td>
                                            <Td>{item.name}</Td>
                                            <Td>{item.email}</Td>
                                            <Td>{item.phone}</Td>
                                            <Td>{item.createdAt}</Td>
                                            <Td>{item.message}</Td>
                                        </Tr>
                                    ))
                                }

                            </Tbody>
                        </Table>
                    </TableContainer>

              {/* Pagination Component */}
              <Box w={'100%'} display="flex" justifyContent="center" mt={6}>
                    <ReactPaginate
                    // pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    // onPageChange={handlePageClick}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                </Box>
                </Box>
            </Grid>
        </>
    )
}

export default AdminContactUser