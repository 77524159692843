import React, { useMemo, useState } from 'react'
import SideBar from '../AdminSideBar/AdminSideBar'
import { Box, Button, Grid, Heading } from '@chakra-ui/react'
import Header from '../AdminHeader/AdminHeader'
import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { MdDelete } from 'react-icons/md'
import { AdminDeleteInvoice, AdminGetAllInvoiceData } from '../../../redux/actions/InvoiceActions'
const AdminInvoice = () => {
    const userDetails = [
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
        { date: '2/10/2024', name: 'Jatender Sighn', subject: 'Generate invoice bill', email: 'j@gmail.com', contactNo: '0987654321' },
    ]
    const { adminInvoice } = useSelector(
        state => state.invoiceContainer
    );
    const dispatch = useDispatch();
    const itemsPerPage = 5; // Set number of items per page
    const [currentPage, setCurrentPage] = useState(0); // Track the current page offset

    // Get the subset of blogs based on the current page
    const billsToShow = useMemo(() => {
        return adminInvoice?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    }, [itemsPerPage, currentPage, adminInvoice])
    const pageCount = useMemo(() => Math.ceil(adminInvoice?.length / itemsPerPage)) // Total pages

    const handlePageClick = (event) => {
        setCurrentPage(event.selected); // Update current page when user clicks pagination
    };
    const deleteInvoice= async (id) => {
        await dispatch(AdminDeleteInvoice(id))
        await dispatch(AdminGetAllInvoiceData())
    }

    // AdminGetAllInvoiceData
    return (
        <>
            <Header />
            <Grid
                w={"100%"}
                height={"100vh"}

                gridTemplateColumns={{ base: "1fr", md: "1fr", lg: "1fr 4fr" }}
                gap={"1rem"}
            >
                <SideBar />
                <Box  bgColor={'#ffff'} overflowY={"auto"} p={3}>
                    <Heading children={'Invoice Bill'} size={'lg'} m={5} textAlign={'center'} textTransform={'uppercase'} />
                    <TableContainer w={'100%'} rounded='sm' boxShadow='sm' bg={'#ECEFE3'} p={5}>
                        <Table variant={'striped'} colorScheme='pink' size='sm' w={'100%'} fontSize={['lg', 'md', 'sm']}>
                            <Thead w={'100%'} >
                                <Tr w={'100%'}>
                                    <Th bg='#7928CA' color='white' p={3}>S.No</Th>
                                    <Th bg='#7928CA' color='white'>Date</Th>
                                    <Th bg='#7928CA' color='white'>Name</Th>
                                    <Th bg='#7928CA' color='white'>ClientName</Th>
                                    <Th bg='#7928CA' color='white'>ClientCity</Th>
                                    <Th bg='#7928CA' color='white'>ClientState</Th>
                                    <Th bg='#7928CA' color='white'>totalAmountWithTax</Th>
                                    <Th bg='#7928CA' color='white'>totalAmount</Th>
                                    <Th bg='#7928CA' color='white'>Action</Th>

                                </Tr>
                            </Thead>
                            <Tbody>

                                {
                                    billsToShow && billsToShow.map((item, i) => (
                                        <Tr key={i} >
                                            <Td>{i + 1}</Td>
                                            <Td>{item?.createdAt}</Td>
                                            <Td>{item?.user?.name}</Td>
                                            <Td>{item?.ClientName}</Td>
                                            <Td>{item?.ClientCity}</Td>
                                            <Td>{item?.ClientState}</Td>
                                            <Td>{item?.totalAmountWithTax}</Td>
                                            <Td>{item?.totalAmount}</Td>
                                            <Td>
                                            <Button onClick={() => deleteInvoice(item._id)} ml={'1.5vmax'} size={'sm'}  color={'white'}  colorScheme='red' children={<MdDelete />} />
                                        </Td>
                                        </Tr>
                                    ))
                                }

                            </Tbody>
                        </Table>
                    </TableContainer>


                            {/* Pagination Component */}
                   <Box w={'100%'} display="flex" justifyContent="center" mt={6}>
                    <ReactPaginate
                    pageCount={pageCount}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    onPageChange={handlePageClick}
                    previousLabel={'<'}
                    nextLabel={'>'}
                    containerClassName={'pagination'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                </Box>
                </Box>
            </Grid>
        </>

    )
}

export default AdminInvoice